import { HttpResponse } from 'msw';
import type { GetResponseResolver, PostResponseResolver } from '~operation/mocks/types/RestResponseResolver';

const EXISTS_BOTH_QUESTIONS = '2';
const SYSTEM_ERROR = '500';

const get: GetResponseResolver = ({ request, params }) => {
  const url = new URL(request.url);
  const answererType = url.searchParams.get('answerer_type');

  const BOSS = 'boss';
  const STAFF = 'staff';

  if (params.companyId === SYSTEM_ERROR) {
    return HttpResponse.json({}, { status: 500 });
  }

  const maxOptions = [...Array(20)].map((n, i) => {
    return {
      id: i + 1,
      name: `回答の選択肢${i + 1}`,
      value: i + 1,
    };
  });

  const questions = {
    hasTranslation: true,
    section: {
      id: 1,
      title: 't'.repeat(400),
      description: 'd'.repeat(1000),
      position: 1,
      questions: [
        {
          id: 2,
          content: 'c'.repeat(1000),
          description: 'd'.repeat(1000),
          options: [
            {
              id: 1,
              name: '0'.repeat(100),
              value: 1,
            },
            {
              id: 2,
              name: '回答の選択肢2',
              value: 2,
            },
            {
              id: 3,
              name: '回答の選択肢3',
              value: 3,
            },
          ],
          type: 'singleCheck',
          position: 1,
          isRequired: true,
          isDisclose: true,
        },
        {
          id: 3,
          content: '設問文2',
          description: '補足文2',
          options: maxOptions,
          type: 'singleSelection',
          position: 2,
          isRequired: false,
          isDisclose: false,
        },
        {
          id: 4,
          content: '設問文3',
          description: '補足文3',
          options: [
            {
              id: 1,
              name: '回答の選択肢1',
              value: 1,
            },
            {
              id: 2,
              name: '回答の選択肢2',
              value: 2,
            },
          ],
          type: 'multiCheck',
          position: 3,
          isRequired: false,
          isDisclose: true,
        },
        {
          id: 5,
          content: '設問文4',
          description: '補足文4',
          options: [],
          type: 'singleLineText',
          position: 4,
          isRequired: false,
          isDisclose: false,
        },
        {
          id: 6,
          content: '設問文5',
          description: '補足文5',
          options: [],
          type: 'multiLineText',
          position: 5,
          isRequired: true,
          isDisclose: true,
        },
      ],
    },
  };

  if (params.surveyId === EXISTS_BOTH_QUESTIONS) {
    return HttpResponse.json(questions, { status: 200 });
  }

  if (answererType === BOSS) {
    return HttpResponse.json(questions, { status: 200 });
  }

  if (answererType === STAFF) {
    return HttpResponse.json(
      {
        hasTranslation: false,
        section: {
          id: null,
          title: null,
          description: null,
          position: null,
          questions: [],
        },
      },
      { status: 200 }
    );
  }
};

const post: PostResponseResolver = () => {
  return HttpResponse.json({}, { status: 200 });
};

export const surveyAdditionalQuestions = {
  get,
  post,
};
