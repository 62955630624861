import { HttpResponse } from 'msw';
import type { GetResponseResolver, PutResponseResolver } from '~operation/mocks/types/RestResponseResolver';

const SYSTEM_ERROR = '500';

const get: GetResponseResolver = ({ request, params }) => {
  const url = new URL(request.url);
  const answererType = url.searchParams.get('answerer_type');
  const lang = url.searchParams.get('lang');

  const BOSS = 'boss';
  const STAFF = 'staff';

  if (params.companyId === SYSTEM_ERROR) {
    return HttpResponse.json({}, { status: 500 });
  }

  if (answererType === BOSS) {
    const maxOptions = [...Array(20)].map((n, i) => {
      return {
        id: i + 1,
        name: `回答の選択肢${i + 1}`,
        value: i + 1,
        isTranslationOutdated: i === 0 ? true : false,
      };
    });

    if (lang === 'en') {
      return HttpResponse.json(
        {
          section: {
            id: 1,
            title: { text: 'Chapter Heading', isTranslationOutdated: true },
            description: { text: 'Description text description text description text', isTranslationOutdated: true },
            questions: [
              {
                id: 2,
                content: { text: 'Question 1', isTranslationOutdated: true },
                description: { text: 'Supplementary text 1', isTranslationOutdated: true },
                options: [
                  {
                    id: 1,
                    name: 'Option 1',
                    value: 1,
                    isTranslationOutdated: true,
                  },
                  {
                    id: 2,
                    name: 'a'.repeat(100),
                    value: 2,
                    isTranslationOutdated: true,
                  },
                  {
                    id: 3,
                    name: 'Option 3',
                    value: 3,
                    isTranslationOutdated: false,
                  },
                ],
                type: 'singleCheck',
              },
              {
                id: 3,
                content: { text: 'Question 2', isTranslationOutdated: true },
                description: { text: 'Supplementary text 2', isTranslationOutdated: true },
                options: maxOptions,
                type: 'singleSelection',
              },
              {
                id: 4,
                content: { text: 'Question 3', isTranslationOutdated: true },
                description: { text: 'Supplementary text 3', isTranslationOutdated: true },
                options: [
                  {
                    id: 1,
                    name: 'Option 1',
                    value: 1,
                    isTranslationOutdated: true,
                  },
                  {
                    id: 2,
                    name: 'Option 2',
                    value: 2,
                    isTranslationOutdated: false,
                  },
                ],
                type: 'multiCheck',
              },
              {
                id: 5,
                content: { text: 'Question 4', isTranslationOutdated: true },
                description: { text: 'Question 4', isTranslationOutdated: true },
                options: [
                  {
                    id: 1,
                    name: '',
                    value: 1,
                    isTranslationOutdated: false,
                  },
                  {
                    id: 2,
                    name: '',
                    value: 2,
                    isTranslationOutdated: false,
                  },
                ],
                type: 'singleLineText',
              },
              {
                id: 6,
                content: { text: 'Question 5', isTranslationOutdated: true },
                description: { text: 'Question 5', isTranslationOutdated: true },
                options: [
                  {
                    id: 1,
                    name: '',
                    value: 1,
                    isTranslationOutdated: false,
                  },
                  {
                    id: 2,
                    name: '',
                    value: 2,
                    isTranslationOutdated: false,
                  },
                ],
                type: 'multiLineText',
              },
            ],
          },
        },
        { status: 200 }
      );
    }

    return HttpResponse.json(
      {
        section: {
          id: 1,
          title: { text: 't'.repeat(400), isTranslationOutdated: true },
          description: { text: 'd'.repeat(1000), isTranslationOutdated: true },
          questions: [
            {
              id: 2,
              content: { text: 'c'.repeat(1000), isTranslationOutdated: true },
              description: { text: 'd'.repeat(1000), isTranslationOutdated: true },
              options: [
                {
                  id: 1,
                  name: '0'.repeat(100),
                  value: 1,
                  isTranslationOutdated: true,
                },
                {
                  id: 2,
                  name: '回答の選択肢2',
                  value: 2,
                  isTranslationOutdated: true,
                },
                {
                  id: 3,
                  name: '回答の選択肢3',
                  value: 3,
                  isTranslationOutdated: false,
                },
              ],
              type: 'singleCheck',
            },
            {
              id: 3,
              content: { text: '設問文2', isTranslationOutdated: false },
              description: { text: '補足文2', isTranslationOutdated: false },
              options: maxOptions,
              type: 'singleSelection',
            },
            {
              id: 4,
              content: { text: '設問文3', isTranslationOutdated: false },
              description: { text: '補足文3', isTranslationOutdated: false },
              options: [
                {
                  id: 1,
                  name: '回答の選択肢1',
                  value: 1,
                  isTranslationOutdated: true,
                },
                {
                  id: 2,
                  name: 'あ'.repeat(100),
                  value: 2,
                  isTranslationOutdated: false,
                },
              ],
              type: 'multiCheck',
            },
            {
              id: 5,
              content: { text: '設問文4', isTranslationOutdated: false },
              description: { text: '設問文4', isTranslationOutdated: false },
              options: [
                {
                  id: 1,
                  name: '',
                  value: 1,
                  isTranslationOutdated: false,
                },
                {
                  id: 2,
                  name: '',
                  value: 2,
                  isTranslationOutdated: false,
                },
              ],
              type: 'singleLineText',
            },
            {
              id: 6,
              content: { text: '設問文5', isTranslationOutdated: false },
              description: { text: '設問文5', isTranslationOutdated: false },
              options: [
                {
                  id: 1,
                  name: '',
                  value: 1,
                  isTranslationOutdated: false,
                },
                {
                  id: 2,
                  name: '',
                  value: 2,
                  isTranslationOutdated: false,
                },
              ],
              type: 'multiLineText',
            },
          ],
        },
      },
      { status: 200 }
    );
  }

  if (answererType === STAFF) {
    if (lang === 'en') {
      return HttpResponse.json(
        {
          section: {
            id: 2,
            title: {
              text: '',
              isTranslationOutdated: false,
            },
            description: {
              text: '',
              isTranslationOutdated: false,
            },
            questions: [
              {
                id: 2,
                type: 'singleCheck',
                content: {
                  text: '',
                  isTranslationOutdated: false,
                },
                description: {
                  text: '',
                  isTranslationOutdated: false,
                },
                options: [
                  {
                    id: 3,
                    value: 0,
                    name: '',
                    isTranslationOutdated: false,
                  },
                  {
                    id: 4,
                    value: 1,
                    name: '',
                    isTranslationOutdated: false,
                  },
                ],
              },
              {
                id: 5,
                type: 'singleSelection',
                content: {
                  text: '',
                  isTranslationOutdated: false,
                },
                description: {
                  text: '',
                  isTranslationOutdated: false,
                },
                options: [
                  {
                    id: 5,
                    value: 1,
                    name: '',
                    isTranslationOutdated: false,
                  },
                  {
                    id: 6,
                    value: 2,
                    name: '',
                    isTranslationOutdated: false,
                  },
                ],
              },
              {
                id: 6,
                type: 'multiCheck',
                content: {
                  text: '',
                  isTranslationOutdated: false,
                },
                description: {
                  text: '',
                  isTranslationOutdated: false,
                },
                options: [
                  {
                    id: 7,
                    value: 1,
                    name: '',
                    isTranslationOutdated: false,
                  },
                  {
                    id: 8,
                    value: 2,
                    name: '',
                    isTranslationOutdated: false,
                  },
                ],
              },
              {
                id: 7,
                type: 'singleLineText',
                content: {
                  text: '',
                  isTranslationOutdated: false,
                },
                description: {
                  text: '',
                  isTranslationOutdated: false,
                },
                options: [],
              },
              {
                id: 8,
                type: 'multiLineText',
                content: {
                  text: '',
                  isTranslationOutdated: false,
                },
                description: {
                  text: '',
                  isTranslationOutdated: false,
                },
                options: [],
              },
            ],
          },
        },
        { status: 200 }
      );
    }

    return HttpResponse.json(
      {
        section: {
          id: 2,
          title: {
            text: '章の見出し',
            isTranslationOutdated: false,
          },
          description: {
            text: '',
            isTranslationOutdated: false,
          },
          questions: [
            {
              id: 2,
              type: 'singleCheck',
              content: {
                text: '設問1',
                isTranslationOutdated: false,
              },
              description: {
                text: '',
                isTranslationOutdated: false,
              },
              options: [
                {
                  id: 3,
                  value: 0,
                  name: '選択肢1',
                  isTranslationOutdated: false,
                },
                {
                  id: 4,
                  value: 1,
                  name: '選択肢2',
                  isTranslationOutdated: false,
                },
              ],
            },
            {
              id: 5,
              type: 'singleSelection',
              content: {
                text: '設問2',
                isTranslationOutdated: false,
              },
              description: {
                text: '補足文2',
                isTranslationOutdated: false,
              },
              options: [
                {
                  id: 5,
                  value: 1,
                  name: '選択肢1',
                  isTranslationOutdated: false,
                },
                {
                  id: 6,
                  value: 2,
                  name: '選択肢2',
                  isTranslationOutdated: false,
                },
              ],
            },
            {
              id: 6,
              type: 'multiCheck',
              content: {
                text: '設問3',
                isTranslationOutdated: false,
              },
              description: {
                text: '',
                isTranslationOutdated: false,
              },
              options: [
                {
                  id: 7,
                  value: 1,
                  name: '選択肢1',
                  isTranslationOutdated: false,
                },
                {
                  id: 8,
                  value: 2,
                  name: '選択肢2',
                  isTranslationOutdated: false,
                },
              ],
            },
            {
              id: 7,
              type: 'singleLineText',
              content: {
                text: '設問4',
                isTranslationOutdated: false,
              },
              description: {
                text: '',
                isTranslationOutdated: false,
              },
              options: [],
            },
            {
              id: 8,
              type: 'multiLineText',
              content: {
                text: '設問5',
                isTranslationOutdated: false,
              },
              description: {
                text: '',
                isTranslationOutdated: false,
              },
              options: [],
            },
          ],
        },
      },
      { status: 200 }
    );
  }
};

const put: PutResponseResolver = () => {
  return HttpResponse.json({}, { status: 200 });
};

export const surveyAdditionalQuestionsTranslation = {
  get,
  put,
};
